const ZoneInMap = [
    {
        id:1,
        region:'Región de los Lagos',
        comuna:'Los lagos',
        longitude:-72.62132459138633,
        latitude: -41.476429209775716
    }
  ]


  export default ZoneInMap;
export const realtorData = {
  email: 'solucionesinmob@unne.cl',
  phone:'56995774163'
  // email: 'contacto@accionpanal.com',
};

export const footerData = {
  email: 'solucionesinmob@unne.cl',
  phone:'9 957 741 63',
  wsp:'9 957 741 63',
}
